import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { regularCoins } from 'utils/constant';
import { Button } from 'components/common/button/button';
import { Input } from 'components/common/form/input';
import PoolTime from '../pool-time/pool-time';

import Styled from './wallet-start.style';

const WalletStart = ({ address = '' }) => {
  const { t } = useTranslation();

  const initialValues = {
    wallet_address: address
  };

  const validate = {
    wallet_address: yup
      .string()
      .required('Wallet Address is a required field')
      .matches(regularCoins.eth, 'Wallet Address not a valid')
  };

  const onSubmitHandler = async (
    values,
    { touched, validateForm, setTouched, setSubmitting }
  ) => {
    const errs = await validateForm();

    if (Object.keys(errs).length === 0) {
      setSubmitting(false);
      navigate(`/minerstats/${values.wallet_address}`);
    } else {
      setTouched({ ...touched, ...errs });
    }
  };

  return (
    <Styled.Wrapper>
      <Formik
        validateOnBlur
        initialValues={initialValues}
        validationSchema={yup.object().shape(validate)}
        onSubmit={onSubmitHandler}
      >
        {({ values, handleChange, handleBlur, touched, errors }) => (
          <Form noValidate>
            <Styled.InputWrapper>
              <Input
                type="text"
                name="wallet_address"
                value={values.wallet_address}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.wallet_address) && touched.wallet_address}
                placeholder={t('wallet.placeholder')}
                required
              />
              <Button disabled={errors.wallet_address} type="submit">
                {t('wallet.startHere')}
              </Button>
            </Styled.InputWrapper>
          </Form>
        )}
      </Formik>
      <PoolTime />
    </Styled.Wrapper>
  );
};

export default WalletStart;
