import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';

import { GET_USER_TELEGRAM_BOT } from 'graphql/user.query';
import { GET_ANONYMOUS_TELEGRAM_BOT } from 'graphql/anonymous.query';
import PlaneIcon from 'components/icons/PlaneIcon';
import { Button } from 'components/common/button/button';
import { CTAHeading, CTAHolder, CTAWrapper } from './call-to-action.style';
import { useUser } from 'contexts/user.context';

const CallToAction = ({ userId = null }) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useUser();
  const context = {};

  if (isAuthenticated) {
    context.context = {
      clientName: 'private'
    };
  }

  const [getUserTelegramBot, { data, loading }] = useLazyQuery(
    isAuthenticated ? GET_USER_TELEGRAM_BOT : GET_ANONYMOUS_TELEGRAM_BOT,
    context
  );

  const onClickHandler = async (e) => {
    e.preventDefault();

    isAuthenticated
      ? await getUserTelegramBot()
      : await getUserTelegramBot({
          variables: { userId }
        });
  };

  useEffect(() => {
    if (data?.userTelegramBot) {
      window.open(data?.userTelegramBot);
    }
  }, [data?.userTelegramBot]);

  return (
    <CTAWrapper>
      <CTAHolder>
        <PlaneIcon />
        <CTAHeading>
          <strong>{t('callToAction.headingAccentText')}</strong>{' '}
          {t('callToAction.headingText')}
        </CTAHeading>
      </CTAHolder>
      <Button loading={loading} onClick={onClickHandler}>
        {t('callToAction.buttonText')}
      </Button>
    </CTAWrapper>
  );
};

export default CallToAction;
