const PlaneIcon = () => {
  return (
    <svg
      width="64"
      height="54"
      viewBox="0 0 64 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6131 16.8891C12.0025 19.354 6.23777 21.8867 0.966303 24.8083C-1.78624 26.8361 1.87208 28.2706 5.30429 29.6161C5.84991 29.83 6.38969 30.0416 6.89727 30.2529C7.31967 30.3837 7.74907 30.5213 8.18431 30.6608C12.0015 31.8844 16.2577 33.2489 19.9636 31.1964C26.0513 27.6777 31.7962 23.6098 37.5368 19.5449C39.4176 18.213 41.2978 16.8816 43.1895 15.5699C43.2782 15.5128 43.3781 15.4476 43.4875 15.3764C45.0989 14.3251 48.7234 11.9612 47.3827 15.2186C44.2126 18.707 40.8168 21.7949 37.4029 24.8998C35.1018 26.9923 32.7924 29.0925 30.5381 31.3279C28.5747 32.9331 26.5358 36.1611 28.7344 38.409C33.7982 41.9759 38.9412 45.4563 44.0812 48.9349C45.7538 50.0667 47.4263 51.1985 49.0954 52.3331C51.9243 54.6057 56.3455 52.7673 56.9674 49.2162C57.244 47.5826 57.5217 45.9492 57.7994 44.3156C59.334 35.2876 60.8691 26.2564 62.2252 17.199C62.4096 15.7782 62.6184 14.3575 62.8275 12.9362C63.3342 9.49122 63.8415 6.04201 64 2.57848C63.5916 -0.878013 59.4264 -0.117913 57.1086 0.659327C45.1946 5.22086 33.3996 10.1205 21.6514 15.1066C20.3205 15.6997 18.9713 16.2924 17.6131 16.8891Z"
        fill="#6487FF"
      />
    </svg>
  );
};

export default PlaneIcon;
