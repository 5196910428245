import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import Heading from 'components/common/heading/heading';

export const Wrapper = styled.div``;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  max-width: 50%;
  margin: 0 auto 40px;

  @media ${themeGet('mediaQueries.tabletPortraitUp')} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;

    margin: 0 auto;

    &:not(:last-child) {
      margin-bottom: 70px;
    }
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  width: 64px;
  height: 64px;
  background-color: ${themeGet('colors.primary.light')};
  border-radius: ${themeGet('radii.medium')};
`;

export const Title = styled.h6`
  font-weight: ${themeGet('fontWeights.regular')};
`;

export const GridHeading = styled(Heading)`
  @media (max-width: 575px) {
    align-items: center;
  }
`;
