import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useQuery } from '@apollo/client';

import {
  GET_ANONYMOUS_HASHRATE,
  GET_ANONYMOUS_TRANSACTIONS,
  GET_ANONYMOUS_USER
} from 'graphql/anonymous.query';

import { useCoins } from 'contexts/coins.context';
import { columnsTransactions } from 'utils/columns-table';

import SEO from 'components/seo';

import WalletStart from 'components/wallet-start/wallet-start';
import Balance from 'components/balance/balance';
import Charts from 'components/charts/charts';
import HashrateStats from 'components/charts/hashrate-stats/hashrate-stats';
import GridTable from 'components/grid-table/grid-table';
import EquipmentIncome from 'components/equipment-income/equipment-income';
import Heading from 'components/common/heading/heading';
import CallToAction from 'components/call-to-action/call-to-action';
import RegistrationBenefits from 'components/registration-benefits/reg-benefits';
import DailyStatistic from 'components/table-block/daily-statistic/daily-statistic';
import { CoinCustomToggler } from 'components/common/coin-toggler/coin-toggler';

import {
  Container,
  MainContentArea,
  Row,
  Section,
  Wrapper
} from 'assets/styles/pages.style';

export default function MinerStatsAddressPage({ params: { address } }) {
  const { t } = useTranslation();

  const { loading, coins } = useCoins();
  const [activeCoin, setActiveCoin] = useState(null);
  const [customCoins, setCustomCoins] = useState([]);

  const { data: user, loading: userLoading } = useQuery(GET_ANONYMOUS_USER, {
    fetchPolicy: 'network-only',
    variables: { address }
  });

  useEffect(() => {
    if (customCoins.length > 0) {
      setActiveCoin(customCoins[0]);
    }
  }, [customCoins]);

  useEffect(() => {
    if (user?.anonymousUser?.wallets) {
      if (
        !loading &&
        coins.length > 0 &&
        user?.anonymousUser?.wallets.length > 0
      ) {
        const result = [];
        coins.forEach((coin) => {
          user?.anonymousUser?.wallets.forEach((item) => {
            if (item.coinId === coin.id) {
              result.push(coin);
            }
          });
        });
        setCustomCoins(result);
      }
    }
  }, [loading, coins, user?.anonymousUser?.wallets]);

  const [
    loadUserHashRate,
    { data: hashrate, loading: hashrateLoading, error: hashrateError }
  ] = useLazyQuery(GET_ANONYMOUS_HASHRATE);

  const [loadUserTransactions, { data: transactions }] = useLazyQuery(
    GET_ANONYMOUS_TRANSACTIONS
  );

  useEffect(() => {
    if (activeCoin?.id && user?.anonymousUser?.id) {
      loadUserHashRate({
        variables: { userId: user?.anonymousUser?.id, coinId: activeCoin?.id }
      });
    }
  }, [activeCoin?.id, loadUserHashRate, user?.anonymousUser?.id]);

  useEffect(() => {
    if (activeCoin?.id && user?.anonymousUser?.id) {
      loadUserTransactions({
        variables: {
          userId: user?.anonymousUser?.id,
          coinId: activeCoin?.id,
          pageNumber: 1,
          pageSize: 5
        }
      });
    }
  }, [activeCoin?.id, loadUserTransactions, user?.anonymousUser?.id]);

  return (
    <MainContentArea>
      <Container>
        <WalletStart address={address} />
        <Row>
          <Balance
            activeCoin={activeCoin}
            balances={user?.anonymousUser?.balances}
            userId={user?.anonymousUser?.id}
            loader={userLoading}
            component={
              <CoinCustomToggler
                state={[activeCoin, setActiveCoin]}
                width="320px"
                coins={customCoins}
              />
            }
          />
        </Row>
        <Row>
          <Wrapper>
            <Charts
              hashrate={hashrate?.anonymousHashRate}
              loading={hashrateLoading}
              error={hashrateError}
            />
            <HashrateStats
              name="anonymousUserInfo"
              workersName="anonymousWorkers"
              userId={user?.anonymousUser?.id}
              activeCoin={activeCoin}
            />
          </Wrapper>
        </Row>
        <Row px={[0, 0, 4]}>
          <Heading mb={4} variant="h3">
            {t('dailyStatisticBlock.title')}
          </Heading>
          {activeCoin?.id && user?.anonymousUser?.id && (
            <DailyStatistic
              userId={user?.anonymousUser?.id}
              activeCoin={activeCoin}
            />
          )}
        </Row>
        <Row px={[0, 0, 4]}>
          <Heading mb={4} variant="h3">
            {t('transactionsBlock.title')}
          </Heading>
          <GridTable
            data={transactions?.anonymousTransactions}
            columns={columnsTransactions}
          />
        </Row>
        <Row>
          <CallToAction userId={user?.anonymousUser?.id} />
        </Row>
        <Row mb={7}>
          <Section p={5}>
            <Heading variant="h3" justifyContent="center">
              {t('equipmentIncomeBlock.titleI18n')}
            </Heading>
            <EquipmentIncome />
          </Section>
        </Row>
        <RegistrationBenefits />
      </Container>
    </MainContentArea>
  );
}

export const Head = () => {
  return <SEO title="Miner Stats" />;
};
