const Benefit2Icon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 8H12C9.8 8 8 9.8 8 12V32C8 34.2 9.8 36 12 36H32C34.2 36 36 34.2 36 32V12C36 9.8 34.2 8 32 8ZM32 14C32 15.1 31.1 16 30 16H14C12.9 16 12 15.1 12 14C12 12.9 12.9 12 14 12H30C31.1 12 32 12.9 32 14ZM20 24V20H24V24H20ZM24 28V32H20V28H24ZM16 24H12V20H16V24ZM28 20H32V24H28V20ZM12 28H16V32H12V28ZM28 32V28H32V32H28ZM6 28H4C1.8 28 0 26.2 0 24V4C0 1.8 1.8 0 4 0H24C26.2 0 28 1.8 28 4V6H24V4H4V24H6V28Z"
        fill="#6487FF"
      />
    </svg>
  );
};

export default Benefit2Icon;
