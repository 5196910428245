import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  width: 100%;

  > form {
    flex: auto;
  }

  &:not(:last-child) {
    margin-bottom: 60px;
  }

  @media ${themeGet('mediaQueries.tabletPortraitUp')} {
    gap: 20px;
  }

  @media ${themeGet('mediaQueries.tabletLandscapeUp')} {
    flex-direction: row;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-radius: ${themeGet('radii.base')};
  box-shadow: ${themeGet('shadows.section')};

  button {
    width: 100%;

    @media ${themeGet('mediaQueries.tabletPortraitUp')} {
      width: auto;
    }
  }

  @media ${themeGet('mediaQueries.tabletPortraitUp')} {
    flex-direction: row;
    padding: 10px;
  }
`;

const Styled = { Wrapper, InputWrapper };

export default Styled;
