import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { ButtonWrapper, Button } from 'components/common/button/button';

import Benefit1Icon from 'components/icons/Benefit1Icon';
import Benefit2Icon from 'components/icons/Benefit2Icon';
import Benefit3Icon from 'components/icons/Benefit3Icon';

import { Row } from 'assets/styles/pages.style';
import {
  Wrapper,
  Grid,
  Item,
  IconWrap,
  Title,
  GridHeading
} from './reg-benefits.style';

const data = [
  {
    title: 'text1',
    icon: Benefit1Icon
  },
  {
    title: 'text2',
    icon: Benefit2Icon
  },
  {
    title: 'text3',
    icon: Benefit3Icon
  }
];

const GridItem = ({ title, icon: Icon }) => {
  const { t } = useTranslation();
  return (
    <Item key={title}>
      <IconWrap>
        <Icon />
      </IconWrap>
      <Title>{t(`registrationBenefits.items.${title}`)}</Title>
    </Item>
  );
};

const RegistrationBenefits = () => {
  const { t } = useTranslation();

  return (
    <Row>
      <GridHeading variant="h3" justifyContent="center">
        {t('registrationBenefits.title')}
      </GridHeading>
      <Wrapper>
        <Grid>{data.map(GridItem)}</Grid>
        <ButtonWrapper>
          <Button onClick={() => navigate('/auth/register')}>
            {t('registrationBenefits.buttonText')}
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </Row>
  );
};

export default RegistrationBenefits;
