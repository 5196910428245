const Benefit3Icon = () => {
  return (
    <svg
      width="40"
      height="28"
      viewBox="0 0 40 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29 14C31.76 14 33.98 11.76 33.98 9C33.98 6.24 31.76 4 29 4C26.24 4 24 6.24 24 9C24 11.76 26.24 14 29 14ZM14 12C17.32 12 19.98 9.32 19.98 6C19.98 2.68 17.32 0 14 0C10.68 0 8 2.68 8 6C8 9.32 10.68 12 14 12ZM29 18C25.34 18 18 19.84 18 23.5V26C18 27.1 18.9 28 20 28H38C39.1 28 40 27.1 40 26V23.5C40 19.84 32.66 18 29 18ZM14 16C9.34 16 0 18.34 0 23V26C0 27.1 0.9 28 2 28H14V23.5C14 21.8 14.66 18.82 18.74 16.56C17 16.2 15.32 16 14 16Z"
        fill="#6487FF"
      />
    </svg>
  );
};

export default Benefit3Icon;
