import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const CTAWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px;
  background-color: ${themeGet('colors.white')};
  box-shadow: ${themeGet('shadows.section')};
  border-radius: ${themeGet('radii.base')};

  @media ${themeGet('mediaQueries.tabletLandscapeUp')} {
    flex-direction: row;
    padding: 40px;
  }

  @media ${themeGet('mediaQueries.mobileOnly')} {
    padding: 20px;
  }

  @media ${themeGet('mediaQueries.mobileOnly')} {
    flex-direction: column;
  }

  svg {
    flex-shrink: 0;
    margin-right: 40px;
  }
`;

export const CTAHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${themeGet('mediaQueries.tabletPortraitUp')} {
    flex-direction: row;
    align-items: center;
  }
`;

export const CTAHeading = styled.h3`
  margin: 40px 0;
  font-weight: ${themeGet('fontWeights.regular')};
  font-size: ${themeGet('fontSizes.lg')}px;

  strong {
    font-weight: ${themeGet('fontWeights.bold')};
  }

  @media ${themeGet('mediaQueries.tabletLandscapeUp')} {
    margin: 0;
  }
`;
