const Benefit1Icon = () => {
  return (
    <svg
      width="36"
      height="44"
      viewBox="0 0 36 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.38 0.72L2.38 6.94C0.94 7.58 0 9.02 0 10.6V20C0 31.1 7.68 41.48 18 44C28.32 41.48 36 31.1 36 20V10.6C36 9.02 35.06 7.58 33.62 6.94L19.62 0.72C18.6 0.26 17.4 0.26 16.38 0.72ZM12.58 30.58L7.4 25.4C6.62 24.62 6.62 23.36 7.4 22.58C8.18 21.8 9.44 21.8 10.22 22.58L14 26.34L25.76 14.58C26.54 13.8 27.8 13.8 28.58 14.58C29.36 15.36 29.36 16.62 28.58 17.4L15.4 30.58C14.64 31.36 13.36 31.36 12.58 30.58Z"
        fill="#6487FF"
      />
    </svg>
  );
};

export default Benefit1Icon;
